import React from 'react'
import Pagination from "react-paginate"
import { useLocation } from 'react-router-dom'

import './styles.scss'

const GaleriaPagination = ({changePage, totalItems, currPage}) => {
    const location = useLocation();

    const onPageChange = (id) => {
        changePage(id);
    }
    let state = {
        proximo: 'Próximo',
        anterior: 'Anterior'
    }

    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }

    if (language === 'P') {
        state.proximo = 'Próximo'
        state.anterior = 'Anterior'
    } else if (language === 'I') {
        state.proximo = 'Next'
        state.anterior = 'Previous'
    } else if (language === 'E') {
        state.proximo = 'Próximo'
        state.anterior = 'Anterior'
    }

    return (
        <Pagination
            pageCount={totalItems}
            pageRangeDisplayed={4}
            marginPagesDisplayed={0}
            containerClassName="pagination"
            nextLabel={ state.proximo }
            previousLabel={ state.anterior }
            itemClassFirst='first'
            itemClassLast='last'
            itemClassPrev="prev"
            itemClassNext="next"
            forcePage={currPage}
            onPageChange={(item) => onPageChange(item.selected + 1)}
        />
    )
}

export default GaleriaPagination