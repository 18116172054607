import React from 'react';
import { useLocation } from 'react-router-dom'

import './styles.scss';



const Footer = (props) => {
    const location = useLocation();
    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }

    return (
        <footer className="Footer wow fadeInUp">
            <h3 className="Footer__brand">Chiquinho Brandão</h3>
            {language === 'P' && (
                <>
                    <a target="_blank" rel="noopener noreferrer" href="mailto:contato@chiquinhobrandao.com.br" className="Footer__email">
                    Contato
                    </a>
                    <hr/>
                    <p className="Footer__copy">
                    © 2020 Chiquinho Brandão. Todos os direitos reservados.
                    </p>
                </>
            )}
            {language === 'I' && (
                <>
                    <a target="_blank" rel="noopener noreferrer" href="mailto:contato@chiquinhobrandao.com.br" className="Footer__email">
                        Contact
                    </a>
                    <hr/>
                    <p className="Footer__copy">
                        © 2020 Chiquinho Brandão. All rights reserved.
                    </p>
                </>
            )}
            {language === 'E' && (
                <>
                    <a target="_blank" rel="noopener noreferrer" href="mailto:contato@chiquinhobrandao.com.br" className="Footer__email">
                        Contacto
                    </a>
                    <hr/>
                    <p className="Footer__copy">
                        © 2020 Chiquinho Brandão. Todos los derechos reservados.
                    </p>
                </>
            )}
        </footer>
    )
}

export default Footer;