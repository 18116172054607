import React, {useState, useEffect} from 'react'
import axios from 'axios.instance'
import {Helmet} from "react-helmet"
import { useLocation } from 'react-router-dom'

import './styles.scss'
import PageBanner from 'components/Sections/PageBanner'
import GallerySlider from 'components/Sections/GallerySlider'

const MeioAmbiente = (props) => {

    // eslint-disable-next-line
    const [isFetching, setIsFetching] = useState(true);
    const [data, setData] = useState(false);
    const location = useLocation()

    let state = {
        titulo: 'Meio Ambiente'
    }

    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }

    if (language === 'P') {
        state.titulo = 'Meio Ambiente'
    } else if (language === 'I') {
        state.titulo = 'Environment'
    } else if (language === 'E') {
        state.titulo = 'Medio Ambiente'
    }

    useEffect(() => {
        axios.get('/pages/meio-ambiente/' + language)
        .then(response => {
            setData(response.data);
        })
        .catch(err => console.log(err))
        . finally(() => {
            setIsFetching(false);
        })
    }, [language]);

    return (
        <section className="MeioAmbiente page-interna pb-5 mb-2 mb-lg-5">
             {data ? 
                <Helmet>
                    <meta name="keywords" content={data.keywords}/>
                    <meta name="description" content={data.description}/>
                    <title>{data.metatitle}</title>
                </Helmet> 
            : null}

            <PageBanner title={ state.titulo } background={data.banner_image || require('../../assets/images/backgrounds/header-meioa.png')}/>
            <main class="pt-3 pb-5 pt-lg-5 my-3 my-lg-5">
                <div className="container pt-4 pt-lg-5">
                    <div className="row">
                        <div className="col-lg-6 d-flex justify-content-between flex-column pb-lg-4">
                            <figure className="my-4 my-lg-0">
                               {data.foto1 ? 
                                (<div
                                    className="img-brush brush-blue-spiral d-flex justify-content-center justify-content-lg-end">
                                    <img
                                        src={data.foto1}
                                        alt={data.legenda_foto1}
                                        style={{
                                        width: '73%'
                                    }}/>
                                </div>) : null}
                                <figcaption className="text-center text-lg-right" dangerouslySetInnerHTML={{
                                    __html: data.legenda_foto1
                                }}></figcaption>
                            </figure>
                            <div className="row pb-lg-4">
                                <div
                                    className="col-lg-3 d-flex align-items-end text-lg-right pr-lg-0 order-last order-lg-first"></div>
                                <div className="col-lg-12">
                                    <figure>
                                        <div className="img-brush">
                                            <img src={data.foto2} alt={data.legenda_foto2}/>
                                        </div>
                                        <figcaption className="text-lg-right" dangerouslySetInnerHTML={{
                                            __html: data.legenda_foto2
                                        }}></figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pt-lg-5 mt-lg-5">
                            <div class="markup pt-lg-5">
                                <h3>
                                   {data.titulo}
                                </h3>
                                <div dangerouslySetInnerHTML={{__html: data.bloco_texto1}}></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="hr-line line-3"></div>
                </div>

                <div className="container-interna">
                    <div className="markup">

                        <figure>
                            <div className="img-brush">
                                <img src={data.foto3} alt={data.legenda_foto3}/>
                            </div>
                            <figcaption dangerouslySetInnerHTML={{
                                    __html: data.legenda_foto3
                                }}></figcaption>
                        </figure>
                        <h4 className="mt-lg-5">
                            { data.titulo2 }
                        </h4>
                        <div dangerouslySetInnerHTML={{__html: data.bloco_texto2}}></div>

                        <figure>
                            <div className="img-brush brush-line brush-line-bottom-right-7">
                                <img src={data.foto4} alt={data.legenda_foto4}/>
                            </div>
                            <figcaption c dangerouslySetInnerHTML={{
                                    __html: data.legenda_foto4
                                }}></figcaption>
                        </figure>
                    </div>
                </div>

                <div className="container my-3 my-lg-5">
                    <figure>
                        <div className="img-brush">
                            <img src={data.foto5} alt={data.legenda_foto5}/>
                        </div>
                        <figcaption dangerouslySetInnerHTML={{
                            __html: data.legenda_foto5
                        }}></figcaption>
                    </figure>
                </div>

                <div className="container-interna">
                    <div className="markup">
                        <div dangerouslySetInnerHTML={{__html: data.bloco_texto3}}></div>
                        <blockquote class="lg" dangerouslySetInnerHTML={{__html: data.citacao}}></blockquote>

                       <div dangerouslySetInnerHTML={{__html: data.bloco_texto4}}></div>

                        <figure>
                            <div className="img-brush">
                                <img src={data.foto6} alt={data.legenda_foto6}/>
                            </div>

                            <figcaption dangerouslySetInnerHTML={{
                                __html: data.legenda_foto6
                            }}></figcaption>
                        </figure>

                      <div dangerouslySetInnerHTML={{__html: data.bloco_texto5}}></div>

                        <GallerySlider data={data} brush="bottom"/>
                    </div>
                </div>
            </main>
        </section>
    )
}

export default MeioAmbiente
