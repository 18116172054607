import React from 'react'
import {scroller as scroll} from 'react-scroll'
import { useLocation } from 'react-router-dom'

import './styles.scss'

const LinhaBottom = ({current, setCurrent}) => {
    const location = useLocation()
    
    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }

    let nextName = '';
    if(current === 0){
        if (language === 'P') {
            nextName = 'Infância'
        } else if (language === 'I') {
            nextName = 'Childhood'
        } else if (language === 'E') {
            nextName = 'Infancia'
        }
    }else if(current === 1){
        if (language === 'P') {
            nextName = 'Juventude'
        } else if (language === 'I') {
            nextName = 'Youth'
        } else if (language === 'E') {
            nextName = 'Juventud'
        }
    }else if(current === 2){
        if (language === 'P') {
            nextName = 'Vida adulta'
        } else if (language === 'I') {
            nextName = 'Adulthood'
        } else if (language === 'E') {
            nextName = 'Edad adulta'
        }
    }else if(current === 3){
        nextName = false
    }

    const onClickBtn = () => {
        setCurrent(current === 3 ? 0 : current + 1)
        scroll.scrollTo('linhatempo-header');
    }

    return(
       <footer className="LinhaBottom">
           {nextName ? <button className="LinhaBottom__btn" onClick={onClickBtn}>
               {nextName}
           </button> : null}
       </footer>
    )
}

export default LinhaBottom