import React, {useState, useEffect, useMemo} from 'react'
import axios from 'axios.instance'
import {Helmet} from "react-helmet"
import { useLocation } from "react-router-dom"

import {HomeSlider} from '../../components/Sections'
import Preloader from 'components/UI/Preloader'

const Home = () => {
    // eslint-disable-next-line
    const [isFetching, setIsFetching] = useState(true);
    const [homeData, setHomeData] = useState(false);
    const [loadingPercentage, setLoadingPercentage] = useState();
    const location = useLocation();

    const config = useMemo(() => ({
        onDownloadProgress: progressEvent => {
            const usualUploadSize = 6136058;
            const total = progressEvent.srcElement.getResponseHeader('Real-Content-Length') || usualUploadSize;
            // eslint-disable-next-line
            let percentCompleted = Math.round((progressEvent.loaded * 100) / total);
            percentCompleted = percentCompleted === 100 ? 99 : percentCompleted;
            setLoadingPercentage(percentCompleted);
        }
    }), []);

    useEffect( () =>{
        let language = 'P'
        if (location.search.includes('language=en')) {
            language = 'I'
        } else if (location.search.includes('language=es')) {
            language = 'E'
        }

        axios.get('/pages/home/' + language, config)
        .then(response => {
            setHomeData(response.data);
        })
        .catch(err => console.log(err))
        .finally(() => {
            setIsFetching(false);
        })
    } ,[config, location.search]);

    return (
        <React.Fragment>
            <Helmet>
                <meta name="keywords" content={homeData.keywords}/>
                <meta name="description" content={homeData.description}/>
                <title>{homeData.metatitle}</title>
            </Helmet>
            <Preloader loadProgress={loadingPercentage} show={isFetching}/>
            <HomeSlider data={homeData}></HomeSlider>
        </React.Fragment>
    )
}

export default Home;