import React, {useState} from 'react'
import {Element} from 'react-scroll'
import {CSSTransition} from 'react-transition-group'
import Modal from 'react-modal'
import axios from 'axios.instance'
import { useLocation } from 'react-router-dom'

import LinhaHeader from './LinhaHeader'
import LinhaBottom from './LinhaBottom'

import './styles.scss';

const customStyles = {
    overlay: {
        zIndex: '9999',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        maxWidth: '500px',
        height: 'max-content',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        margin: 'auto auto',
        backgroundColor: '#691A17'
    }
}

const LinhaTempo = ({data}) => {
    const [current, setCurrent] = useState(0);
    const location = useLocation();

    const [modalIsOpen, setIsOpen] = React.useState(false)
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')

    function openModal() {
        setIsOpen(true)
    }
    function closeModal() {
        setIsOpen(false)
    }

    let state = {
        registro: {
            title: 'Conheça melhor a história de Chiquinho Brandão na sua autobiografia',
            subtitle: 'O que pode dar certo',
            link: 'Clique aqui e faça o Download',
        },
        origens: 'Origenss',
        infancia: 'Infância',
        juventude: 'Juventude',
        vidaAdulta: 'Vida Adulta',
        inputNome: 'Digite seu nome',
        inputEmail: 'Digite seu melhor e-mail',
        enviar: 'Enviar'
    }

    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }

    if (language === 'P') {
        state.registro.title = 'Conheça melhor a história de Chiquinho Brandão na sua autobiografia'
        state.registro.subtitle = 'O que pode dar certo'
        state.registro.link = 'Clique aqui e faça o Download'
        state.origens = 'Origens'
        state.infancia = 'Infância'
        state.juventude = 'Juventude'
        state.vidaAdulta = 'Vida Adulta'
        state.inputNome = 'Digite seu nome'
        state.inputEmail = 'Digite seu melhor e-mail'
        state.enviar = 'Enviar'
    }
    if (language === 'I') {
        state.registro.title = 'Learn more about the story of Chiquinho Brandão in his autobiography'
        state.registro.subtitle = 'What can work'
        state.registro.link = 'Click here to download'
        state.origens = 'Origins'
        state.infancia = 'Childhood'
        state.juventude = 'Youth'
        state.vidaAdulta = 'Adulthood'
        state.inputNome = 'Type your name'
        state.inputEmail = 'Enter your best email'
        state.enviar = 'Send'
    }
    if (language === 'E') {
        state.registro.title = 'Conozca más sobre la historia de Chiquinho Brandão en su autobiografía'
        state.registro.subtitle = 'Que puede funcionar'
        state.registro.link = 'Haga click aquí para descargar'
        state.origens = 'Orígenes'
        state.infancia = 'Infancia'
        state.juventude = 'Juventud'
        state.vidaAdulta = 'Edad adulta'
        state.inputNome = 'Escriba su nombre'
        state.inputEmail = 'Ingrese su mejor correo electrónico'
        state.enviar = 'Enviar'
    }

    function sendData() {
        if (nome.length > 3 && email.length > 3) {
            axios.get(`cadastra_user_biografia/${nome}/${email}`)
            .then(response => {
                console.log('sucesso', response)
                const link = document.createElement('a');
                link.href = response.data.link
                link.setAttribute('download', true)
                link.setAttribute('target', '_blank')
                document.body.appendChild(link)
                link.click();
                link.parentNode.removeChild(link)
                setNome('')
                setEmail('')
                closeModal()
            })
            .catch(error => {
                console.log('error', error)
            })
        } else {
            alert('Preencha o formulário completo!')
        }
    }

    const Content0 = () => (
        <div>
            <h3 className="LinhaTempo__title">
                { state.origens }
            </h3>
            <div className="markup">
                <h4>{data.timeline1_titulo}</h4>
                <div dangerouslySetInnerHTML={{__html: data.timeline1_bloco_texto1}}></div>
                <figure className="pt-4">
                    <div className="img-brush brush-line brush-line-top-left-2">
                        <img src={data.timeline1_foto1} alt={data.timeline1_legenda_foto1}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline1_legenda_foto1}}></figcaption>
                </figure>

                <figure className="pt-3">
                    <div className="img-brush brush-line brush-line-bottom-right">
                        <img src={data.timeline1_foto2} alt={data.timeline1_legenda_foto2}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline1_legenda_foto2}}></figcaption>
                </figure>
                <div class="openModal">
                    <p>{ state.registro.title }</p>
                    <strong><i>{ state.registro.subtitle }</i></strong>
                    <button onClick={openModal}>{ state.registro.link }</button>
                </div>
            </div>
        </div>
    )

    const Content1 = () => (
        <div>
            <h3 className="LinhaTempo__title">
                { state.infancia }
            </h3>
            <div className="markup">
                <h4>{data.timeline2_titulo}</h4>
               
                <div dangerouslySetInnerHTML={{__html: data.timeline2_bloco_texto1}}></div>
                <figure className="pt-lg-5 mt-lg-3">
                    <div className="img-brush brush-line brush-line-top-left-2">
                        <img src={data.timeline2_foto1} alt={data.timeline2_legenda_foto1}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline2_legenda_foto1}}></figcaption>
                </figure>

                <figure className="">
                    <div className="img-brush brush-line brush-line-bottom-right">
                       <img src={data.timeline2_foto2} alt={data.timeline2_legenda_foto2}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline2_legenda_foto2}}></figcaption>
                </figure>

                <div dangerouslySetInnerHTML={{__html: data.timeline2_bloco_texto2}}></div>

                <blockquote dangerouslySetInnerHTML={{__html: data.timeline2_citacao}}></blockquote>

                <div dangerouslySetInnerHTML={{__html: data.timeline2_bloco_texto3}}></div>

                <figure className="">
                    <div className="img-brush brush-line brush-line-bottom-right-2">
                        <img src={data.timeline2_foto3} alt={data.timeline2_legenda_foto3}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline2_legenda_foto3}}></figcaption>
                </figure>

                 <div dangerouslySetInnerHTML={{__html: data.timeline2_bloco_texto4}}></div>

                <h4>
                    {data.timeline2_titulo2}
                </h4>

                <div dangerouslySetInnerHTML={{__html: data.timeline2_bloco_texto5}}></div>

                <figure>
                    <div className="img-brush">
                        <img src={data.timeline2_foto4} alt={data.timeline2_legenda_foto4}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline2_legenda_foto4}}></figcaption>
                </figure>
                <div class="openModal">
                    <p>{ state.registro.title }</p>
                    <strong><i>{ state.registro.subtitle }</i></strong>
                    <button onClick={openModal}>{ state.registro.link }</button>
                </div>

            </div>
        </div>

    )

    const Content2 = () => (
        <div>
            <h3 className="LinhaTempo__title">
                { state.juventude }
            </h3>
            <div className="markup">
                <h4>
                    {data.timeline3_titulo}
                </h4>

               <div dangerouslySetInnerHTML={{__html: data.timeline3_bloco_texto1}}></div>

                <h4>
                    {data.timeline3_titulo2}
                </h4>

                <div dangerouslySetInnerHTML={{__html: data.timeline3_bloco_texto2}}></div>

                <blockquote dangerouslySetInnerHTML={{__html: data.timeline3_citacao}}></blockquote>

                <div dangerouslySetInnerHTML={{__html: data.timeline3_bloco_texto3}}></div>

                <h4>{data.timeline3_titulo3}</h4>

                <div dangerouslySetInnerHTML={{__html: data.timeline3_bloco_texto4}}></div>

                <figure>
                    <img src={data.timeline3_foto1} alt={data.timeline3_legenda_foto1}/>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline3_legenda_foto1}}></figcaption>
                </figure>

                <div dangerouslySetInnerHTML={{__html: data.timeline3_bloco_texto5}}></div>

                <figure>
                    <div className="img-brush brush-line brush-line-bottom-right-3">
                        <img src={data.timeline3_foto2} alt={data.timeline3_legenda_foto2}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline3_legenda_foto2}}></figcaption>
                </figure>

                <h4>{data.timeline3_titulo4}</h4>

                <div dangerouslySetInnerHTML={{__html: data.timeline3_bloco_texto6}}></div>

                <blockquote dangerouslySetInnerHTML={{__html: data.timeline3_citacao2}}></blockquote>

                <div dangerouslySetInnerHTML={{__html: data.timeline3_bloco_texto7}}></div>

                <figure>
                    <img src={data.timeline3_foto3} alt={data.timeline3_legenda_foto3}/>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline3_legenda_foto3}}></figcaption>
                </figure>

                <div dangerouslySetInnerHTML={{__html: data.timeline3_bloco_texto8}}></div>

                <figure>
                    <div className="img-brush brush-line brush-line-bottom-right-4">
                        <img src={data.timeline3_foto4} alt={data.timeline3_legenda_foto4}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline3_legenda_foto4}}></figcaption>
                </figure>
                <div class="openModal">
                    <p>{ state.registro.title }</p>
                    <strong><i>{ state.registro.subtitle }</i></strong>
                    <button onClick={openModal}>{ state.registro.link }</button>
                </div>
            </div>

        </div>
    )

    const Content3 = () => (
        <div>
            <h3 className="LinhaTempo__title">
                { state.vidaAdulta }
            </h3>
            <div className="markup">
                <h4>
                    {data.timeline4_titulo}
                </h4>

                <div dangerouslySetInnerHTML={{__html: data.timeline4_bloco_texto1}}></div>

                <div className="row">
                    <div className="col-lg-6">
                        <figure>
                            <div className="img-brush brush-line brush-line-bottom-left">
                                <img src={data.timeline4_foto1} alt={data.timeline4_legenda_foto1} height="460" style={{objectFit: 'cover'}}/>
                            </div>
                            <figcaption class="text-lg-right" dangerouslySetInnerHTML={{__html: data.timeline4_legenda_foto1}}></figcaption>
                        </figure>
                    </div>
                    <div className="col-lg-6">
                        <figure>
                            <div className="img-brush">
                                <img src={data.timeline4_foto2} alt={data.timeline4_legenda_foto2}  height="460" />
                            </div>
                            <figcaption dangerouslySetInnerHTML={{__html: data.timeline4_legenda_foto2}}></figcaption>
                        </figure>
                    </div>
                </div>

                <blockquote dangerouslySetInnerHTML={{__html: data.timeline4_citacao}}></blockquote>

                <div dangerouslySetInnerHTML={{__html: data.timeline4_bloco_texto2}}></div>

                <h4> {data.timeline4_titulo2}</h4>
               
                <div dangerouslySetInnerHTML={{__html: data.timeline4_bloco_texto3}}></div>

                <blockquote dangerouslySetInnerHTML={{__html: data.timeline4_citacao2}}>
                </blockquote>


                <div dangerouslySetInnerHTML={{__html: data.timeline4_bloco_texto4}}></div>

                <h4>{data.timeline4_titulo3}</h4>
               
                <div dangerouslySetInnerHTML={{__html: data.timeline4_bloco_texto5}}></div>

                <figure>
                    <div className="img-brush">
                        <img src={data.timeline4_foto3} alt={data.timeline4_legenda_foto3}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline4_legenda_foto3}}></figcaption>
                </figure>

                <h4>{data.timeline4_titulo4}</h4>

                <div dangerouslySetInnerHTML={{__html: data.timeline4_bloco_texto6}}></div>

                <figure>
                    <div className="img-brush brush-line brush-line-bottom-right-tall">
                        <img src={data.timeline4_foto4} alt={data.timeline4_legenda_foto4}/>
                    </div>
                    <figcaption class="text-lg-right" dangerouslySetInnerHTML={{__html: data.timeline4_legenda_foto4}}></figcaption>
                </figure>

                <div dangerouslySetInnerHTML={{__html: data.timeline4_bloco_texto7}}></div>

                <h4>{data.timeline4_titulo5}</h4>

                <div dangerouslySetInnerHTML={{__html: data.timeline4_bloco_texto8}}></div>

                <figure>
                    <div className="img-brush brush-line brush-line-bottom-right-tall">
                        <img src={data.timeline4_foto5} alt={data.timeline4_legenda_foto5}/>
                    </div>
                    <figcaption dangerouslySetInnerHTML={{__html: data.timeline4_legenda_foto5}}></figcaption>
                </figure>
                <div class="openModal">
                    <p>{ state.registro.title }</p>
                    <strong><i>{ state.registro.subtitle }</i></strong>
                    <button onClick={openModal}>{ state.registro.link }</button>
                </div>
            </div>
        </div>
    )

    return (
        <Element name="LinhaTempo">
            <section className="LinhaTempo">
                <LinhaHeader setCurrent={setCurrent} current={current}/>
                <div className="container-interna LinhaTempo__content">
                    <CSSTransition
                        unmountOnExit={true}
                        in={current === 0}
                        timeout={500}
                        classNames="CSSTransition--fade">
                        <Content0/>
                    </CSSTransition>
                    <CSSTransition
                        unmountOnExit={true}
                        in={current === 1}
                        timeout={500}
                        classNames="CSSTransition--fade">
                        <Content1/>
                    </CSSTransition>
                    <CSSTransition
                        unmountOnExit={true}
                        in={current === 2}
                        timeout={500}
                        classNames="CSSTransition--fade">
                        <Content2/>
                    </CSSTransition>
                    <CSSTransition
                        unmountOnExit={true}
                        in={current === 3}
                        timeout={500}
                        classNames="CSSTransition--fade">
                        <Content3/>
                    </CSSTransition>
                </div>
                <LinhaBottom setCurrent={setCurrent} current={current}/>
                <Modal 
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <div class="podeDarCerto">
                        {language === 'P' && (
                            <h1>O que pode dar certo - digital</h1>
                        )}
                        {language === 'I' && (
                            <h1>What can work - digital</h1>
                        )}
                        {language === 'E' && (
                            <h1>Qué puede funcionar - digital</h1>
                        )}
                        <input type="text" placeholder={ state.inputNome } value={nome} onInput={e => setNome(e.target.value)}/>
                        <input type="email" placeholder={ state.inputEmail } value={email} onInput={e => setEmail(e.target.value)}/>
                        <button onClick={sendData}>
                            { state.enviar }
                        </button>
                    </div>
                </Modal>
            </section>
        </Element>
    )
}

export default LinhaTempo