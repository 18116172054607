import React, {useState, useEffect} from 'react'
import axios from 'axios.instance'
import {Helmet} from "react-helmet"
import { useLocation } from "react-router-dom"

import './styles.scss'
import PageBanner from 'components/Sections/PageBanner'
import LinhaTempo from 'components/Sections/LinhaTempo'

const Historia = (props) => {
    // eslint-disable-next-line
    const [isFetching, setIsFetching] = useState(true);
    const [data, setData] = useState(false);
    const location = useLocation();
    let titulo = 'História'

    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }
    useEffect( () =>{

        axios.get('/pages/historia/' + language)
        .then(response => {
            setData(response.data);
        })
        .catch(err => console.log(err))
        .finally(() => {
            setIsFetching(false);
        })
    }, [ language ]);

    if (language === 'P') {
        titulo = 'História'
    } else if (language === 'I') {
        titulo = 'History'
    } else if (language === 'E') {
        titulo = 'Historia'
    }

    return (
        <section className="Historia page-interna">
             {data ? 
                <Helmet>
                    <meta name="keywords" content={data.keywords}/>
                    <meta name="description" content={data.description}/>
                    <title>{data.metatitle}</title>
                </Helmet> 
            : null}
            
            <PageBanner title={ titulo } background={data.banner_image || require('../../assets/images/backgrounds/header-historia.png')}/>
            <main className="pt-3 pb-5 pt-lg-5 my-3 my-lg-5">
                <div className="container pt-5 mt-lg-4">
                    <div className="row">
                        <div className="col-lg-6 d-flex justify-content-between flex-column pb-lg-4">
                            <figure className="my-4 my-lg-0" >
                                {data.foto1 ? 
                                (
                                    <div className="img-brush brush-blue">
                                        <img src={data.foto1} alt={data.legenda_foto1}/>
                                    </div>
                                ): null}
                                <figcaption className="text-lg-right" dangerouslySetInnerHTML={{
                                    __html: data.legenda_foto1
                                }}></figcaption>
                            </figure>
                            <div className="row mt-3 mt-lg-0">
                                <div className="col-lg-3 d-flex align-items-end text-lg-right pr-lg-0 order-last order-lg-first">
                                <figcaption dangerouslySetInnerHTML={{
                                    __html: data.legenda_video1
                                }}></figcaption>
                                </div>
                                <div className="col-lg-9">
                                    <div className="img-brush brush-line brush-line-top-left">
                                        {/* eslint-disable-next-line */}
                                        <iframe
                                            width="100%"
                                            height="230"
                                            src={data.video1}
                                            frameborder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pt-lg-5">
                            <div className="markup">
                                <h3 className="mt-5 mt-lg-0">
                                    {data.titulo}
                                </h3>
                                <p>
                                    <b>
                                        {data.subtitulo}
                                    </b>
                                </p>
                                <div dangerouslySetInnerHTML={{__html: data.bloco_texto1}}>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: data.bloco_texto2}}>
                                </div>
                                <div dangerouslySetInnerHTML={{__html: data.bloco_texto3}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LinhaTempo data={data}/>
            </main>
        </section>
    )
}

export default Historia;