import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet"
import axios from 'axios.instance'
import {useLocation} from 'react-router-dom'

import Slick from 'react-slick'

import './styles.scss';
import PageBanner from 'components/Sections/PageBanner';

const Empreendedorismo = (props) => {
    // eslint-disable-next-line
    const [isFetching,
        setIsFetching] = useState(true);
    const [data,
        setData] = useState(false);
    const location = useLocation();
    let state = {
        titulo: 'Empreendedorismo'
    }

    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }

    if (language === 'P') {
        state.titulo = 'Empreendedorismo'
    } else if (language === 'I') {
        state.titulo = 'Entrepreneurship'
    } else if (language === 'E') {
        state.titulo = 'Emprendimiento'
    }

    const carouselSettings = {
        dots: false,
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: (
            <div className="arrow-right"></div>
        ),
        prevArrow: (
            <div className="arrow-right"></div>
        ),
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        axios
            .get('/pages/empreendedorismo/' + language)
            .then(response => {
                setData(response.data);
            })
            .catch(err => console.log(err))
            . finally(() => {
                setIsFetching(false);
            })
    }, [language]);

    return (
        <section className="Empreendedorismo page-interna pb-4 mb-2 mb-lg-5">
            {data
                ? <Helmet>
                        <meta name="keywords" content={data.keywords}/>
                        <meta name="description" content={data.description}/>
                        <title>{data.metatitle}</title>
                    </Helmet>
                : null}

            <PageBanner
                title={state.titulo}
                background={data.banner_image || require('../../assets/images/backgrounds/header-empreendedorismo.png')}/>
            <main className="pt-3 pb-5 pt-lg-5 my-3 my-lg-5">
                <div className="container pt-4 pt-lg-5">
                    <div className="row">
                        <div className="col-lg-6 d-flex justify-content-between flex-column pb-lg-4">
                            <figure className="my-4 my-lg-0">
                                {data.foto1
                                    ? (
                                        <div className="img-brush brush-orange">
                                            <img src={data.foto1} alt={data.legenda_foto1}/>
                                        </div>
                                    )
                                    : null}
                                <figcaption
                                    className="text-lg-right"
                                    dangerouslySetInnerHTML={{
                                    __html: data.legenda_foto1
                                }}></figcaption>
                            </figure>
                            <div className="row pb-lg-4 pt-4 pt-lg-0">
                                <div
                                    className="col-lg-3 d-flex align-items-end text-lg-right pr-lg-0 order-last order-lg-first">
                                    <figcaption
                                        dangerouslySetInnerHTML={{
                                        __html: data.legenda_foto2
                                    }}></figcaption>
                                </div>
                                <div className="col-lg-9">
                                    <div className="img-brush">
                                        <img src={data.foto2} alt={data.legenda_foto2}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pt-lg-5">
                            <div className="markup pt-4 pt-lg-1">
                                <h3>
                                    {data.titulo}
                                </h3>
                                <div
                                    dangerouslySetInnerHTML={{
                                    __html: data.bloco_texto1
                                }}></div>
                                <blockquote
                                    dangerouslySetInnerHTML={{
                                    __html: data.citacao
                                }}></blockquote>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-interna">
                    <div className="markup">
                        <div
                            dangerouslySetInnerHTML={{
                            __html: data.bloco_texto2
                        }}></div>
                    </div>
                </div>

                <div className="container">
                    <div className="hr-line line-1"></div>
                </div>

                <div className="container-interna">
                    <div className="markup">
                        <h4>
                            {data.titulo2}
                        </h4>
                        <div
                            dangerouslySetInnerHTML={{
                            __html: data.bloco_texto3
                        }}></div>

                        <blockquote
                            dangerouslySetInnerHTML={{
                            __html: data.citacao2
                        }}></blockquote>

                        <div
                            dangerouslySetInnerHTML={{
                            __html: data.bloco_texto4
                        }}></div>

                        <figure>
                            <div className="img-brush">
                                <img src={data.foto3} alt={data.legenda_foto3}/>
                            </div>
                            <figcaption
                                dangerouslySetInnerHTML={{
                                __html: data.legenda_foto3
                            }}></figcaption>
                        </figure>

                        <div
                            dangerouslySetInnerHTML={{
                            __html: data.bloco_texto5
                        }}></div>

                        <h4>{data.titulo3}</h4>

                        <div
                            dangerouslySetInnerHTML={{
                            __html: data.bloco_texto6
                        }}></div>

                        <blockquote
                            dangerouslySetInnerHTML={{
                            __html: data.citacao3
                        }}></blockquote>

                        <div
                            dangerouslySetInnerHTML={{
                            __html: data.bloco_texto7
                        }}></div>

                        <figure>
                            <div className="img-brush">
                                <img src={data.foto4} alt={data.legenda_foto4}/>
                            </div>
                            <figcaption
                                dangerouslySetInnerHTML={{
                                __html: data.legenda_foto4
                            }}></figcaption>
                        </figure>
                    </div>
                </div>

                <div className="container">
                    <div className="hr-line line-4"></div>
                </div>

                <div className="Empreendedorismo__carousel">
                    <div className="container">
                        <h2 className="Empreendedorismo__carousel-title">
                            Francisco Brandão na Mídia
                        </h2>

                        <Slick {...carouselSettings}>
                            {data
                                ? data
                                    .posts_midia
                                    .map((item, index) => (
                                        <div key={index} className="wrapper">
                                            <div className="Empreendedorismo__carousel-item">
                                                <div>
                                                    <h3 className="title">
                                                        {item.title}
                                                    </h3>
                                                    <p className="desc">
                                                        {item.content}
                                                    </p>
                                                </div>
                                                <a href={item.link_url} target="_blank" className="more">Veja mais</a>
                                            </div>
                                        </div>
                                    ))
                                : null}
                        </Slick>

                    </div>
                </div>
            </main>

        </section>
    )
}

export default Empreendedorismo;