import React, {useState} from 'react'
import { useLocation } from 'react-router-dom'

import './styles.scss'

const GaleriaFiltros = ({data, setParentFiltros}) => {
    const [filtros,
        setFiltros] = useState({nomeArtista: '', nomeObra: '', tipoObra: '', dataDe: '', dataAte: ''});
    const location = useLocation();
    let listArtistas = [];
    // eslint-disable-next-line
    let listNomes = [];
    // eslint-disable-next-line
    let listTipos = [];

    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }

    if(data){
        listArtistas = data.map( i => i.file_artista).filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        }).filter(function(str) {
            return /\S/.test(str) && str !== null;
        }).sort(function(a, b){
            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
        });

        listNomes = data.map( i => i.file_desc2).filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        }).filter(function(str) {
            return /\S/.test(str) && str !== null;
        });

        listTipos = data.map( i => i.file_tipo).filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        }).filter(function(str) {
            return /\S/.test(str) && str !== null;
        });
    }

    const updateFiltros = (key, value) =>{
        let newFiltros = {...filtros};
        newFiltros[key] = value;
        setFiltros(newFiltros);
    }

    const sendFiltros = () => {
        setParentFiltros(filtros);
    }

    return (
        <header className="GaleriaFiltros my-5">
            <div className="container">
                {language === 'P' && (
                    <>
                        <h1 className="GaleriaFiltros__title">
                            Filtros
                        </h1>

                        <div className="row">
                            <div className="col-lg-3 mb-3 mb-lg-0 col-12 pr-lg-0">
                                <div className="form-control">
                                    <label htmlFor="nome-artista">Nome do Artista</label>
                                    <select name="nome-artista" id="nome-artista" onChange={(e) => updateFiltros('nomeArtista', e.target.value) }>
                                        <option selected disabled>Selecione</option>
                                        <option value="">Todos</option>
                                        {listArtistas.map( i => (
                                            <option value={i}>
                                                {i}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-3 mb-lg-0 col-12">
                                <div className="form-control">
                                    <label
                                        htmlFor=""
                                        style={{
                                        opacity: 0
                                    }}>.</label>
                                    <button className="btn-outline-white w-100" onClick={sendFiltros}>Filtrar</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {language === 'I' && (
                    <>
                        <h1 className="GaleriaFiltros__title">
                            Filters
                        </h1>

                        <div className="row">
                            <div className="col-lg-3 mb-3 mb-lg-0 col-12 pr-lg-0">
                                <div className="form-control">
                                    <label htmlFor="nome-artista">Name of the Artist</label>
                                    <select name="nome-artista" id="nome-artista" onChange={(e) => updateFiltros('nomeArtista', e.target.value) }>
                                        <option selected disabled>Select</option>
                                        <option value="">All</option>
                                        {listArtistas.map( i => (
                                            <option value={i}>
                                                {i}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-3 mb-lg-0 col-12">
                                <div className="form-control">
                                    <label
                                        htmlFor=""
                                        style={{
                                        opacity: 0
                                    }}>.</label>
                                    <button className="btn-outline-white w-100" onClick={sendFiltros}>Filter</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {language === 'E' && (
                    <>
                        <h1 className="GaleriaFiltros__title">
                            Filtros
                        </h1>

                        <div className="row">
                            <div className="col-lg-3 mb-3 mb-lg-0 col-12 pr-lg-0">
                                <div className="form-control">
                                    <label htmlFor="nome-artista">Nombre del artista</label>
                                    <select name="nome-artista" id="nome-artista" onChange={(e) => updateFiltros('nomeArtista', e.target.value) }>
                                        <option selected disabled>Seleccione</option>
                                        <option value="">Todos</option>
                                        {listArtistas.map( i => (
                                            <option value={i}>
                                                {i}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 mb-3 mb-lg-0 col-12">
                                <div className="form-control">
                                    <label
                                        htmlFor=""
                                        style={{
                                        opacity: 0
                                    }}>.</label>
                                    <button className="btn-outline-white w-100" onClick={sendFiltros}>Filtrar</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </header>
    )
}

export default GaleriaFiltros