import React from 'react'
import {Element} from 'react-scroll'
import { useLocation } from 'react-router-dom'

// eslint-disable-next-line
import styles from './styles.scss'

const LinhaHeader = ({current, setCurrent}) => {
    const location = useLocation();
    let state = {
        trajetoria: 'Trajetória',
        origens: 'Origenss',
        infancia: 'Infância',
        juventude: 'Juventude',
        vidaAdulta: 'Vida Adulta'
    }

    let language = 'P'
    if (location.search.includes('language=en')) {
        language = 'I'
    } else if (location.search.includes('language=es')) {
        language = 'E'
    }
    
    if (language === 'P') {
        state.trajetoria = 'Trajetoria'
        state.origens = 'Origens'
        state.infancia = 'Infância'
        state.juventude = 'Juventude'
        state.vidaAdulta = 'Vida Adulta'
    }
    if (language === 'I') {
        state.trajetoria = 'Trajectory'
        state.origens = 'Origins'
        state.infancia = 'Childhood'
        state.juventude = 'Youth'
        state.vidaAdulta = 'Adulthood'
    }
    if (language === 'E') {
        state.trajetoria = 'Trayectoria'
        state.origens = 'Orígenes'
        state.infancia = 'Infancia'
        state.juventude = 'Juventud'
        state.vidaAdulta = 'Edad adulta'
    }

    return (
        <Element name="linhatempo-header">
            <header className="LinhaHeader">
            <div className="container">
                <div className="LinhaHeader__title">
                    { state.trajetoria }
                </div>
                <div className="LinhaHeader__list" data-current={current}>
                    <div className="overlay-line"></div>
                    <button onClick={() => setCurrent(0)} data-active={current > -1}>
                        <span>
                            { state.origens }
                        </span>
                    </button>
                    <button onClick={() => setCurrent(1)} data-active={current > 0}>
                        <span>
                            { state.infancia }
                        </span>
                    </button>
                    <button onClick={() => setCurrent(2)} data-active={current > 1}>
                        <span>
                            { state.juventude }
                        </span>
                    </button>
                    <button onClick={() => setCurrent(3)} data-active={current > 2}>
                        <span>
                            { state.vidaAdulta }
                        </span>
                    </button>
                </div>
            </div>
        </header>
        </Element>
    )
}

export default LinhaHeader